var cookieBar = $(".js-cookie");
var statisticsCookies = false;
var socialCookies = false;
var contentNavigable = false;
var expiryDate =  new Date();
var isCookieForm = false;
var isNoCookiesButton = false;
expiryDate.setMonth(expiryDate.getMonth() + 6);
var cookiesModal = new bootstrap.Modal(document.getElementById("cookies"));

var twReferrer,
    twText,
    twUrl;

var cookies = function () {
    blockContent();
    cookieBar.removeClass("u-hidden");
    cookieBar.cookieBar({closeButton: ".js-cookie-button, .js-cookie-no-button", hideOnClose: false, name: 'cookie-bar', expiresDays: expiryDate});

    cookieBar.on('cookieBar-close', function () {
        isCookieForm != true && !isNoCookiesButton && chosenCookies();
        isCookieForm != true && isNoCookiesButton && noCookies();
        applyJs();
        cookieBar.hide();
        blockContent();
        $('.modal').modal('hide');
    });
};

$('.js-cookie-no-button').on('click', function(e){
    isNoCookiesButton = true;
    e.preventDefault();
});

$('#js-show-cookie-bar').on('click', function(e){
    e.preventDefault();
    cookieBar.show();
});


$('.js-cookies-all').on('click', function (e) {
    e.preventDefault();
    isCookieForm = true;
    $('.js-cookie-form :checkbox').each(function () {
        if (this.value === '2') {
            statisticsCookies = this.checked;
            $.cookie('cookie-settings-statistics', this.checked, {
                path: '/',
                expires: expiryDate
            });
        }

        if (this.value === '3') {
            socialCookies = this.checked;
            $.cookie('cookie-settings-social', this.checked, {
                path: '/',
                expires: expiryDate
            });
        }

    });


    if ($.cookie('cookie-bar') !== 'hide') {
        $.cookie('cookie-bar', 'hide', {
            path: '/',
            expires: expiryDate
        });
        cookieBar.trigger('cookieBar-close');
    }
    $('.js-cookie-result').removeClass("u-hidden");
});

var blockContent = function () {
    if (document.cookie.indexOf('cookie-bar') < 0 && !contentNavigable) {
        $('html').css({'overflow': 'hidden'});

        cookiesModal.show();
    } else {
        $('html').css({'overflow-x': 'auto', 'overflow-y': 'scroll'});
        resetContentState();

        cookiesModal.hide();
    }
};

var resetContentState = function () {
    $('.js-cookie').removeClass('is-readable');
    $('body > header, body > footer').removeClass('is-disabled');
};

var chosenCookies = function () {
    statisticsCookies = true;
    $.cookie('cookie-settings-statistics', true, {
        path: '/',
        expires: expiryDate
    });

    socialCookies = true;
    $.cookie('cookie-settings-social', true, {
        path: '/',
        expires: expiryDate
    });
};

var noCookies = function () {
    $.cookie('cookie-settings-statistics', false, {
        path: '/',
        expires: expiryDate
    });
    $.cookie('cookie-settings-social', false, {
        path: '/',
        expires: expiryDate
    });
};

var applyJs = function () {
    // applyOswaldJs();
    socialCookies && applySocialJs();
    statisticsCookies && applyAnalyticsJs();
};

// var applyOswaldJs = function () {
//     var e, t;
//     if (marriedOswald) {
//         e = new XMLHttpRequest;
//         if (e.open("GET", "https://api.oswald.ai/api/v1/chats/e5877570-8f47-11e8-8358-e9478a7bc908/widget?locale="+locale, !1), e.send(null), 200 == e.status) {
//             t = document.createElement("script");
//             t.innerHTML = e.responseText, document.body.appendChild(t);
//         }
//     }
// };

var applySocialJs = function () {
    // console.log('social loaded');
    socialCookies = true;

    $('#social').prop( "checked", true );
    // console.log($('#social'));
    var lang = locale === 'fr' ? 'fr_FR' : 'nl_NL';

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/" + lang + "/sdk.js#xfbml=1&version=v2.3";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    appendTwitterJs();
};

var appendTwitterJs = function () {
    var twitterContainer = $('#twitter-widget-0');
    if (twitterContainer.length) {
        twitterContainer.attr('src', '//platform.twitter.com/widgets/tweet_button.20ed5cfd7315475c9d1d523194b1645f.en.html#_=1429700747413&amp;count=horizontal&amp;dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;original_referer=' + twReferrer + '&amp;size=m&amp;text=' + twText + '&amp;url=' + twUrl + '');
        !function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.src = "//platform.twitter.com/widgets.js";
                fjs.parentNode.insertBefore(js, fjs);
            }
        }(document, "script", "twitter-wjs");
    }
};


var applyAnalyticsJs = function () {

    statisticsCookies = true;
    $('#statistic').prop( "checked", true );
    if(ShowGoogleAnalytics){
        // console.log('analytics loaded');
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        ga('create', googleAnalytics, 'auto');
        ga('send', 'pageview');

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',googleTagManager);
    }
};

var showCalculationHelloCustomer = function (type) {
    if(statisticsCookies){
        var storedAry = JSON.parse($.cookie('calculation-hello-customer'));

        if(storedAry && storedAry.includes(type)){
            return false;
        }

        return true;
    }
    return true;

};

var saveCookeHelloCustomer = function (type) {
  if(statisticsCookies) {
    var storedAry = JSON.parse($.cookie('calculation-hello-customer'));

    if(!storedAry){
      storedAry = [];
    }

    storedAry.push(type);
    var expiryDateCustomer =  new Date();
    expiryDateCustomer.setMonth(expiryDateCustomer.getMonth() + 3);
    $.cookie('calculation-hello-customer', JSON.stringify(storedAry), {
      path: '/',
      expires: expiryDateCustomer
    });
  }
}

cookies();
